@import "./../../../styles/bootstrap-override/common.scss";

.wrapper-hack {
  &[class*="mb-2"] {
    margin-bottom: map-get($spacers, 2) !important;
  }

  &[class*="mb-4"] {
    margin-bottom: map-get($spacers, 4) !important;
  }
}

.form-group--date {
  max-width: rem(200);
}

.switch-group {
  display: flex;
  overflow-x: auto;
  max-width: min-content;
  border: 1px solid $gray-500;
  border-radius: $border-radius;
  &:focus-within {
    border-color: $input-focus-border-color;
  }
}

div.switch-wrapper {
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  input {
    opacity: 0;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
  }

  input:checked + .switch {
    background-color: rgba($blue, 0.15);
    color: $blue;
    box-shadow: none;
  }
}

label.switch:not(.btn-link) {
  box-shadow: none;
  font-weight: $base-font-weight;
}

.required {
  color: $danger;
}
