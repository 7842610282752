@import "../../../styles/bootstrap-override/common.scss";

.layout {
  background-color: $white;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 2rem;
  max-width: 330px;
  min-height: 100vh;
  margin: 0 auto;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.form {
  justify-self: center;
  margin-top: auto;
  margin-bottom: auto;
}

.logo {
  margin-bottom: 4.375rem;
}

.background {
  clip-path: polygon(5% 0, 100% 0, 100% 100%, 0% 100%);
  margin-right: -$container-padding-x;
  img {
    object-fit: cover;
    width: 100%;
    height: 100vh;
    object-position: center center;
  }
}
